const regexForNames = /^[a-zA-Z]{2,25}$/;
const regexForPhoneNumber = /\d{9}$/;
const ShopertinoConfig = {
  currency: 'EUR',
  appIdentifier: 'rn-social-network-android',
  
  SHOPIFY_CONFIG: {
    domain: 'relish-official.myshopify.com',
    storefrontAccessToken: '61c1e9deaf3973cce71a054c0cdbd834',
    apiKey: '3eca6eb4861920da0ba00c6a9a02a99f',
    password: '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',
  },
  relishEnv: {
    API: {
      baseURL: 'https://connect.relish.it/',
      timeout: 15000,
      username: 'mario.rossi',
      password: 'pointbreak',
      linkURL: 'https://dashboard.connect.relish.it/',
    },
    APIOLD: {
      baseURL: 'http://localhost:8000/',
      timeout: 15000,
      username: 'mario.rossi',
      password: 'pointbreak',
      linkURL: 'http://localhost:3000/',
    },
  },
  newArrivalsId: "261254250554",

};

export default ShopertinoConfig;

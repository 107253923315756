import "./App.css";
import { useState, useEffect } from "react";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import Media from "./components/mediaDashboard";
import Collection from "./components/collectionDashboard";
import logo from "./styles/logo_relish.png";
import { Modal, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { relishBackendAPIManager } from "./apis/Relish";
import AppConfig from './ShopertinoConfig';
import Home from './components/Home';
import Login from './components/Login';

// import Images from "./components/Images";

function App() {
  
  return (
    <Router>
      <Route exact path="/">
        <Login/>
      </Route>
      <Route exact path="/store-italia">
        <Home/>
      </Route>
    </Router>  
    //COLLECTION CAROUSEL
  );
}

export default App;

import React from "react";
import "../App.css";
import { useState, useEffect } from "react";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import Media from "./mediaDashboard";
import Collection from "./collectionDashboard";
import logo from "../styles/logo_relish.png";
import { Modal, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { relishBackendAPIManager } from "../apis/Relish";
import AppConfig from '../ShopertinoConfig';

export default function Home() {

    const [addLabel, setAddLabel] = useState([]);
    const [InputLabelSelected, setInputLabelSelected] = useState({});
    const [addImage, setAddImage] = useState([]);
    const [InputImageSelected, setInputImageSelected] = useState({});
    const [addCategory, setAddCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({
        label: "",
        image: "",
    });
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState({
        label: "",
        image: "",
    });
    const [editato, setEditCatgories] = useState({
        id: "",
        testo: "",
    });
    const [modalValue, setModalValue] = useState({
        label: "",
        image: "",
    });

    useEffect(() => {
        fetchUserToken();
        refreshData();

        return () => {
        console.log(edit);
        };
    }, [edit]); //quando cambia questo valore viene chiamato useEffect

    // const getImmagine = () => {
    //       relishBackendAPIManager.getDashboardBE(({ carosel, categoryIds }) => {
    //     if (carosel) {
    //       console.log(carosel);
    //       return carosel.immagine;
    //     }
    //     if (categoryIds) {
    //       console.log(categoryIds);
    //       return categoryIds.immagine;
    //     }
    //   });
    // }


  function refreshData() {
    relishBackendAPIManager.getDashboardBE(
      ({ carosel, categoryIds, categoriesLabel }) => {
        if (carosel) {
          console.log("************** getDashboardBE carosel");
          setAddImage([...carosel]);
          console.log(carosel);
        }
        if (categoryIds) {
          console.log("************** getDashboardBE categoryIds");
          setAddCategory([...categoryIds]);
          console.log(categoryIds);
        }
        if (categoriesLabel) {
          console.log("************** getDashboardBE categoriesLabel");
          setAddLabel([...categoriesLabel]);
          console.log(categoriesLabel);
        }
      }
    );
  }
  const fetchUserToken = async () => {
    console.log("************** fetchUserToken");
    relishBackendAPIManager.refreshToken();
  };

  const closeModal = () => setShow(false);

  //INSERISCO INDEX PER IDENTIFICARE LA POSIZIONE DI UN ELEMENTO NELL'ARRAY
  const openModal = (category, index) => {
    setEdit({ category, index });
    setShow(true);
  };
  const addValue = (event) => {
    setEditCatgories({
      id: event.target.value,
      testo: event.target.value,
    });
  };

  // const addCategoryHandler = () => {};
  // const [deletedImageName, setDeletedImageName] = useState("");

  const uploadEvent = async (id) => {
    if(InputImageSelected.size<=600000){
      let formData: FormData = new FormData();
      formData.append("photo", InputImageSelected);
      formData.append("dashboard_id", 1);
      formData.append("id", id);

      const result = await relishBackendAPIManager.addCarouselImage(formData);
      if(result.success === true){
        refreshData();
      }

    }
    else{
      alert("Immagine troppo grande");
    }
  };

  const aggiungiImmagine = async () => {
    setAddImage([...addImage, InputImageSelected]);
    let formData: FormData = new FormData();
    if(InputImageSelected.size<=600000){
    formData.append("photo", InputImageSelected);
    formData.append("dashboard_id", 1);

    const result = await relishBackendAPIManager.addNewCarouselImage(formData);
    if(result.success === true){
      refreshData();
      
    }

  }else{
    alert("Immagine troppo grande");
  }
  };

  const deleteImage = (id) => {
    relishBackendAPIManager.deleteOneCarouselImage(id);
    window.location.reload();
  };

  const imageSelected = (event) => {
    setInputImageSelected(event.target.files[0]);
  };

  const selectedCategoryHandler = (event) => {
    setSelectedCategory({
      label: "",
      image: event.target.files[0],
    });
    console.log(event.target.files[0]);
  };


  const saveCategoryChanges = async (id, IdCategoies) => {
    let formData: FormData = new FormData();
    if(selectedCategory.image.size <= 600000){
    formData.append("photo", selectedCategory.image);
    formData.append("dashboard_id", 1);
    formData.append("category_id", IdCategoies);
    formData.append("id", id);

    const result = await relishBackendAPIManager.addCategoryImage(formData);
    if(result.success === true){
      refreshData();
    }
    closeModal();
    //window.location.reload();

  }else {
    alert("Immagine troppo grande");
  }
  };


        return(
            <Router>
            <div className="App">
                <div className="App-header">
                <div className="logo">
                    <img src={logo} className="logo-img" alt="" />
                </div>

                <div className="menu">
                    <ul className="menu-list">
                    <li className="menu-item">
                        <NavLink
                        activeStyle={{ color: "#ff8c64" }}
                        exact={true}
                        className="link-item"
                        to="/store-italia"
                        >
                        Store Italia
                        </NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink
                        activeStyle={{ color: "#ff8c64" }}
                        className="link-item"
                        to="/store-europa"
                        >
                        Store Europa
                        </NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink
                        activeStyle={{ color: "#ff8c64" }}
                        className="link-item"
                        to="/store-internazionale"
                        >
                        Store Internazionale
                        </NavLink>
                    </li>
                    </ul>
                </div>
                </div>
                {/* home carousel */}

                <div className="main_container">
                <Switch>
                    {/* <Route exact={true} path="/">
                    <RedirectLogin />
                    </Route> */}
                    <Route exact={true} path="/Store-Italia">
                    <div className="main_container">
                      
                        <p className="section-title">Home Carousel</p>
                        <div className="box_img">
                        {addImage.map((iteratore, index = 0) => (
                            <div className="images_array">
                            <div className="delete_btn">
                                <button className="btn-delete">
                                <FaRegTrashAlt
                                    className="fa-icon"
                                    onClick={() => deleteImage(iteratore.id)}
                                />
                                </button>
                            </div>
                            <img
                                className="single_img"
                                src={iteratore.immagine}
                                alt="img__dashboard"
                                key={iteratore.immagine}
                            />
                            <input
                                type="file"
                                accept=".jpg,.png"
                                className="input"
                                onChange={imageSelected}
                            />

                            <button
                                className="upload_button"
                                onClick={() => uploadEvent(iteratore.id)}
                            >
                                Aggiorna
                            </button>
                            </div>
                        ))}
                        </div>
                        <div className="upload_img_container">
                        <input
                            id="aggiungi_img"
                            type="file"
                            accept=".jpg,.png"
                            className="input"
                            onChange={imageSelected}
                        />
                        <button className="upload_button" onClick={aggiungiImmagine}>
                            Aggiungi
                        </button>
                        </div>
                    </div>
                    {/* collection carousel */}
                    <div className="main_container">
                        <p className="section-title">Collections Carousel</p>
                        <div className="box_img">
                        {addCategory.map((category, index) => (
                            <div key={category.label} className="images_arraycat">
                            <div className="label-category">
                                <p className="category-label">{category.label}</p>
                                <div className="icons-edit-delete">
                                <button className="btn-delete">
                                    <FaRegEdit
                                    onClick={() => openModal(category, index)} //itero add Category per prendere l'oggetto dove ho cliccato
                                    className="fa-icon"
                                    id="edit-icon"
                                    />
                                </button>
                                </div>
                            </div>
                            <img
                                className="single_imgcat"
                                src={category.immagine}
                                alt=""
                                key={category.immagine}
                            />
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="container-select-save">
                        <div className="container-select">
                        {/* <div className="container-select-input">
                            <select
                            value={selectedCategory.label}
                            name="scegli-categoria"
                            id="multiple-select"
                            onChange={selectedOptionHandler}
                            >
                            <option value="opt-1">Seleziona Categoria</option>
                            <option value="Estate">Estate</option>
                            <option value="Inverno">Inverno</option>
                            <option value="Primavera">Primavera</option>
                            <option value="Autunno">Autunno</option>
                            <option value="SS 2021/2022">SS 2021/2022</option>
                            <option value="SW 2021/2022">SW 2021/2022</option>
                            </select>
                            <input
                            type="file"
                            className="input"
                            onChange={selectedCategoryHandler}
                            />
                        </div> */}
                        {/* <button
                            className="upload_button_select"
                            onClick={uploadCategory}
                        >
                            Aggiungi
                        </button> */}
                        <Modal show={show}>
                            <Modal.Header>
                            {" "}
                            {edit.category && (
                                <Modal.Title>
                                Modifica i valori della categoria {edit.category.label}
                                </Modal.Title>
                            )}
                            </Modal.Header>
                            <Modal.Body>
                            Seleziona nuova categoria e immagine
                            </Modal.Body>
                            <select
                            value={edit.label}
                            name="scegli-categoria"
                            id="multiple-select-modal"
                            onChange={addValue}
                            >
                            <option>seleziona una nuova categoria (opzionale)</option>
                            {addLabel.map((categoriesLabel, index) => (
                                
                                <option value={categoriesLabel.id}>
                                {categoriesLabel.testo}
                                </option>
                            ))}
                            </select>
                            <input
                            value={edit.image}
                            type="file"
                            accept=".jpg,.png"
                            id="multiple-select-modal"
                            onChange={selectedCategoryHandler}
                            />
                            <Modal.Footer>
                            <Button
                                onClick={() =>
                                saveCategoryChanges(edit.category.id,editato.id)
                                }
                                variant="success"
                            >
                                Salva
                            </Button>
                            <Button onClick={closeModal} variant="danger">
                                Chiudi
                            </Button>
                            </Modal.Footer>
                        </Modal>
                        </div>
                    </div>
                    </Route>

                    <Route path="/store-europa">
                    <Collection />
                    </Route>
                    <Route path="/store-internazionale">
                    <Media />
                    </Route>
                </Switch>
                </div>
            </div>
            </Router>
        )
}

import AppConfig from '../../ShopertinoConfig';


const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';


export default class RelishBackendAPIManager {
    static allCategories = null;

    constructor(client) {
        this.client = client;
    }

    getRequest = async (endPoint) => {
        try {
            const response = await this.client.get(endPoint, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return { ...response, success: true };
        } catch (error) {
            const msgError = error.response ? error.response : error;
            console.log('msgError: ', msgError);
            return { msgError, success: false };
        }
    };

    postRequest = async (endPoint, body) => {
        try {
            const response = await this.client.post(endPoint, body, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return { ...response, success: true };
        } catch (error) {
            const msgError = error.response ? error.response : error;
            console.log('msgError: ', msgError);
            return { msgError, success: false };
        }
    };
    deleteRequest = async (endPoint, id) => {
        try {
            const response = await this.client.delete(endPoint +id);

            return { ...response, success: true };
        } catch (error) {
            const msgError = error.response ? error.response : error;
            console.log('msgError: ', msgError);
            return { msgError, success: false };
        }
    };

    multipartPostRequest = async (endPoint, body) => {
        try {
            const response = await this.client.post(endPoint, body, {
                headers: {
                }
            });

            return { ...response, success: true };
        } catch (error) {
            const msgError = error.response ? error.response : error;
            console.log('msgError: ', msgError);
            return { msgError, success: false };
        }
    };

    login = (username, password) => {
        const endPoint = '/api/v1/user-auth';
        const body = {
            username,
            password
        };

        return this.postRequest(endPoint, body);
    };

    checkToken = (token) => {
        const endPoint = '/api/v1/check-token';
        const body = {
            token,
        };

        return this.postRequest(endPoint, body);
    };

    dashboard = () => {
        const endPoint = '/api/v1/dashboard/1';

        return this.getRequest(endPoint);
    };
    dashboardEuropa = () => {
        const endPoint = '/api/v1/dashboard/2';

        return this.getRequest(endPoint);
    };
    dashboardInternazionale = () => {
        const endPoint = '/api/v1/dashboard/3';

        return this.getRequest(endPoint);
    };
    categories = () => {
        const endPoint = '/api/v1/getAllCategories/1';

        return this.getRequest(endPoint);
    };
    categoriesEuropa = () => {
        const endPoint = '/api/v1/getAllCategories/2';

        return this.getRequest(endPoint);
    };
    categoriesInternazionale = () => {
        const endPoint = '/api/v1/getAllCategories/3';

        return this.getRequest(endPoint);
    };

    getToken = async () => {
        const response = await this.login(AppConfig.relishEnv.API.username, AppConfig.relishEnv.API.password);

        //console.log(`response: ${JSON.stringify(response)}`);
        if (response.success && response?.data?.token) {
            //console.log(`token: ` + response?.data?.data?.data?.token);
            localStorage.setItem(ACCESS_TOKEN_KEY, response?.data?.token);
        }

    };

    refreshToken = async () => {
        const token = localStorage.getItem(ACCESS_TOKEN_KEY);
        let response;
        if (token) {
            response = await this.checkToken(token);
            if (response.success) {
                response = response?.data;
            }

        }

        if (!token || !response.success) {
            response = await this.login(AppConfig.relishEnv.API.username, AppConfig.relishEnv.API.password);
            if (response.success) {
                response = response?.data?.data;
            }
        }

        //console.log(`response: ${JSON.stringify(response)}`);
        if (response.status) {
            console.log(`response: ${JSON.stringify(response)}`);
            localStorage.setItem(ACCESS_TOKEN_KEY, response?.data?.token);
        }

    };

async getDashboardBE(callback) {

        let carosel = [];
        let categoryIds = [];
        let categoriesLabel =[];
        let immagine = '';
        let id=0;
        let oggettoDasCrousell ={immagine:null,id:null};
        let oggettoDashCategories= {immagine:null,id: null,label:''};
        var label = '';
        let testoCateories = '';
        let idCategories=0;
        let oggettoCategories={id: '',test:''};

        const response = await this.dashboard();
        const responseCategories = await this.categories();

        if (responseCategories.success && responseCategories?.data?.status) {
          //  console.log(`responseCategories: ${JSON.stringify(responseCategories?.data)}`);
            categoriesLabel = responseCategories?.data.data.map((iteratore) => {
                testoCateories=iteratore.testo;
                idCategories=iteratore.id;
                oggettoCategories={id: idCategories,testo: testoCateories};
                
                return oggettoCategories;

            });

        }


            
        if (response.success && response?.data?.status) {
        //console.log(`response: ${JSON.stringify(response?.data)}`);
        carosel = response?.data.carousell.map((iteratore) => {

            immagine= AppConfig.relishEnv.API.baseURL + 'public/img/' + iteratore.immagine;
            id=iteratore.id;
            oggettoDasCrousell = {immagine: immagine,id: id};
           
           
            return oggettoDasCrousell;

        });
        categoryIds = response?.data.categories.map((category) => {
            immagine= AppConfig.relishEnv.API.baseURL + 'categoria/img/' + category.img;
            id=category.id;
            label=category.testo;
            oggettoDashCategories = {immagine: immagine,id: id,label: label};
            
            return oggettoDashCategories;
        });
      
        }

        callback && callback({ id,carosel, categoryIds, categoriesLabel });

    };
    async getDashboardBEEuropa(callback) {

        let carosel = [];
        let categoryIds = [];
        let categoriesLabel =[];
        let immagine = '';
        let id=0;
        let oggettoDasCrousell ={immagine:null,id:null};
        let oggettoDashCategories= {immagine:null,id: null,label:''};
        var label = '';
        let testoCateories = '';
        let idCategories=0;
        let oggettoCategories={id: '',test:''};

        const response = await this.dashboardEuropa();
        const responseCategories = await this.categoriesEuropa();




        if (responseCategories.success && responseCategories?.data?.status) {
          //  console.log(`responseCategories: ${JSON.stringify(responseCategories?.data)}`);
            categoriesLabel = responseCategories?.data.data.map((iteratore) => {
                testoCateories=iteratore.testo;
                idCategories=iteratore.id;
                oggettoCategories={id: idCategories,testo: testoCateories};
                
                return oggettoCategories;

            });

        }


            
        if (response.success && response?.data?.status) {
        //console.log(`response: ${JSON.stringify(response?.data)}`);
        carosel = response?.data.carousell.map((iteratore) => {

            immagine= AppConfig.relishEnv.API.baseURL + 'public/img/' + iteratore.immagine;
            id=iteratore.id;
            oggettoDasCrousell = {immagine: immagine,id: id};
           
           
            return oggettoDasCrousell;

        });
        categoryIds = response?.data.categories.map((category) => {
            immagine= AppConfig.relishEnv.API.baseURL + 'categoria/img/' + category.img;
            id=category.id;
            label=category.testo;
            oggettoDashCategories = {immagine: immagine,id: id,label: label};
            
            return oggettoDashCategories;
        });
      
        }

        callback && callback({ id,carosel, categoryIds, categoriesLabel });

    };async getDashboardBEInternazionale(callback) {

        let carosel = [];
        let categoryIds = [];
        let categoriesLabel =[];
        let immagine = '';
        let id=0;
        let oggettoDasCrousell ={immagine:null,id:null};
        let oggettoDashCategories= {immagine:null,id: null,label:''};
        var label = '';
        let testoCateories = '';
        let idCategories=0;
        let oggettoCategories={id: '',test:''};

        const response = await this.dashboardInternazionale();
        const responseCategories = await this.categoriesInternazionale();




        if (responseCategories.success && responseCategories?.data?.status) {
          //  console.log(`responseCategories: ${JSON.stringify(responseCategories?.data)}`);
            categoriesLabel = responseCategories?.data.data.map((iteratore) => {
                testoCateories=iteratore.testo;
                idCategories=iteratore.id;
                oggettoCategories={id: idCategories,testo: testoCateories};
                
                return oggettoCategories;

            });

        }


            
        if (response.success && response?.data?.status) {
        //console.log(`response: ${JSON.stringify(response?.data)}`);
        carosel = response?.data.carousell.map((iteratore) => {

            immagine= AppConfig.relishEnv.API.baseURL + 'public/img/' + iteratore.immagine;
            id=iteratore.id;
            oggettoDasCrousell = {immagine: immagine,id: id};
           
           
            return oggettoDasCrousell;

        });
        categoryIds = response?.data.categories.map((category) => {
            immagine= AppConfig.relishEnv.API.baseURL + 'categoria/img/' + category.img;
            id=category.id;
            label=category.testo;
            oggettoDashCategories = {immagine: immagine,id: id,label: label};
            
            return oggettoDashCategories;
        });
      
        }

        callback && callback({ id,carosel, categoryIds, categoriesLabel });

    };
    deleteOneCarouselImage = (id) => {
        const endPoint = '/api/v1/dashboard/deleteOneCarosell/';
       
        return this.deleteRequest(endPoint, id);
    };
    addNewCarouselImage = (formData) => {
        const endPoint = '/api/v1/dashboard/postNewCarousell';

        return this.multipartPostRequest(endPoint, formData);
    };
    addCarouselImage = (formData) => {
        const endPoint = '/api/v1/dashboard/postCarosell';

        return this.multipartPostRequest(endPoint, formData);
    };

    addCategoryImage = (formData) => {
        const endPoint = '/api/v1/dashboard/postCategories';

        return this.multipartPostRequest(endPoint, formData);
    };


}
import axios from 'axios';
import RelishBackendAPIManager from './RelishBackendAPIManager';
import AppConfig from '../../ShopertinoConfig';

const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';

const apiClient = axios.create({
    baseURL: AppConfig.relishEnv.API.baseURL,
});

apiClient.interceptors.request.use(
    async (config) => {
        const token = await localStorage.getItem(ACCESS_TOKEN_KEY);
        if (token) config.headers.Authorization = `Bearer ${token}`;
        //    console.log('***************** '+config.headers.Authorization)
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

export const relishBackendAPIManager = new RelishBackendAPIManager(apiClient);

import React from "react";
import "../App.css";
import { useState, useEffect } from "react";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import Media from "./mediaDashboard";
import Collection from "./collectionDashboard";
import logo from "../styles/logo_relish.png";
import { Modal, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { relishBackendAPIManager } from "../apis/Relish";
import AppConfig from '../ShopertinoConfig';
import LoginStyle from "../styles/login.css";

export default function Login() {
  const [user, setUser] = useState([]);
  const [password, setPassword] = useState([]);

  const loginFunction = async () => {
    
    const result= await relishBackendAPIManager.login(user,password);
   
    if(result.success ===true ){
      window.location.replace(AppConfig.relishEnv.API.linkURL+'store-italia');
    }else{
      alert("Credenziali Sbagliate");
    }
   
  }

  const usernameInput = (e) => {
    setUser(e.target.value)
  }
  const passwordInput = (e) => {
    setPassword(e.target.value)
  }

  return (
    <div className="App">
      <div className="login-div">
        <img src={logo} className="img-login" />
        <div class="form-div">
          <label className="label-login" >Username</label>
          <input className="input-login" onChange={usernameInput} type="text" />
          <label className="label-login">Password</label>
          <input className="input-login" onChange={passwordInput} type="password" />
          <button className="button-login" onClick={loginFunction}>Login</button>
        </div>
      </div>
    </div>
  )
}
